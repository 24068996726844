/* You can add global styles to this file, and also import other style files */

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-Light.ttf');
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-Medium.ttf');
//     font-weight: 500;
// }

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-SemiBold.ttf');
//     font-weight: 600;
// }

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-Bold.ttf');
//     font-weight: 700;
// }

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-ExtraBold.ttf');
//     font-weight: 800;
// }

// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url('/src/assets/fonts/Poppins-Black.ttf');
//     font-weight: 900;
// }

// @font-face {
//     font-family: 'Inter', sans-serif;
//     src: url('/src/assets/fonts/Inter-Regular.ttf');
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'Inter', sans-serif;
//     src: url('/src/assets/fonts/Inter-Medium.ttf');
//     font-weight: 500;
// }

// MAIN STYLING STARTS

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// 
$primary-color: #1777D0;
$secondary-color: #82BEF5;
$table-heading-color: #C3E5F4;

body {
    background: #ffffff !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: $primary-color !important;
    background-color: $primary-color !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: $primary-color !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Poppins', sans-serif !important;
}

.h-100v {
    height: 100vh !important;
}

.w-49 {
    width: 49% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pr-2 {
    padding-right: 15px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.bold {
    font-weight: 700 !important;
}

.disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.new-popup {
    .popup-header {
        background: #fff !important;
        height: auto !important;
        padding: 20px !important;

        h2 {
            font-size: 16px !important;
            color: #3e3e3e !important;
        }
    }

    .popup-body {
        padding: 0px 40px 20px !important;
        height: auto !important;
        // &.storage{
        //     height: 590px !important;
        // }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.mat-select-search-input {
    background: #fff !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px !important;
}

.primary-btn {
    background: $primary-color !important;
    border: none !important;
    outline: none !important;
    border-radius: 10px !important;
    height: 54px !important;
    color: #F0F0F0 !important;
    font-weight: 900;
}

.downloadbtn {
    font-size: 13px !important;
    font-weight: 500;
    height: 40px !important;

    mat-icon {
        width: 15px !important;
        height: 12px !important;
        font-size: 15px !important;
    }
}

.secondary-btn {
    background: none !important;
    border: 1px solid rgba(23, 119, 208, 0.5) !important;
    color: $primary-color !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
    padding: 18px 9px !important;
    font-size: 12px !important;
    border-radius: 10px !important;
}

.creation-btn {
    background: $primary-color !important;
    color: #FFF !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: 0px;
    height: 40px !important;
    padding: 0 15px !important;

    mat-icon {
        font-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
        margin-left: 5px !important;
    }

    &.new {
        padding: 7px 15px !important;
        border-radius: 6px !important;
        border: 1px solid rgb(188 188 188 / 80%) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 21px !important;
        color: rgb(70 70 70 / 80%) !important;
        background-color: transparent !important;
    }
}

.creation-btn.secondary-btn {
    background: none !important;
    border: 1px solid rgba(23, 119, 208, 0.5) !important;
    color: $primary-color !important;
    border-radius: 5px !important;
}

.delete-btn {
    background: #F95C5C !important;
    color: #FFF !important;
    font-weight: 900;
    font-family: "Poppins", sans-serif !important;
    border-radius: 10px !important;
    height: 54px !important;
}

button:disabled {
    opacity: 0.5 !important;
}

.req-mark {
    font-size: 14px !important;
    color: rgb(230, 63, 63) !important;
}

.paginator {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: rgba(0, 0, 0, 0.1) !important;
    }

    .mat-mdc-paginator .mdc-notched-outline__leading,
    .mat-mdc-paginator .mdc-notched-outline__notch,
    .mat-mdc-paginator .mdc-notched-outline__trailing {
        border-color: rgba(0, 0, 0, 1);
    }

    .mat-mdc-paginator .mat-mdc-select-value-text {
        color: rgba(0, 0, 0, 1) !important;
        font-weight: 600 !important;
    }

    .mdc-text-field--focused:not(.mdc-test-field--disabled) .mdc-floating-label {
        color: #000 !important;
        font-weight: 500 !important;
    }

    .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
        fill: rgba(0, 0, 0, 0.2) !important;
    }

    .mat-mdc-paginator-icon {
        fill: rgba(0, 0, 0, 1);
    }
}


// .mat-mdc-select-arrow-wrapper svg path {
//     stroke: rgba(0, 0, 0, 1) !important;
// }

.mat-datepicker-toggle {
    color: $primary-color !important;
}

.pickup-date {

    .mat-mdc-icon-button {
        --mat-mdc-button-persistent-ripple-color: transparent;
        --mat-mdc-button-ripple-color: transparent;
    }
}

.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 0px !important;
}

.mat-mdc-form-field-icon-prefix>.mat-icon,
.mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 8px !important;
}

.main-content {
    width: 100%;
    // padding: 0 20px;
    background: #ffffff;
    overflow: hidden;
}

.main-content-container {
    overflow: hidden;
}

.main-padding {
    padding: 0 20px !important;
    // max-width: calc(100vw - 16.3rem);
    max-width: 100%;

    &.master-base {
        padding-right: 0 !important;
    }
}

.body-content {
    height: calc(100vh - 4rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0 0;
    transition: 0.3s;

    &.header-hide {
        height: calc(100vh - 0.1rem) !important;
    }
}

.body-content::-webkit-scrollbar,
.mat-mdc-dialog-container .mdc-dialog__surface::-webkit-scrollbar {
    width: 6px;
}

.body-content::-webkit-scrollbar-track,
.mat-mdc-dialog-container .mdc-dialog__surface::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.body-content::-webkit-scrollbar-thumb,
.mat-mdc-dialog-container .mdc-dialog__surface::-webkit-scrollbar-thumb {
    background-color: #7e7e7e;
    border-radius: 40px;
}

// AUTHENTICATION MODULE STARTS
.login-picture {
    background: $secondary-color;

    img {
        width: 580px;
        height: 550px;
    }

    h4 {
        position: absolute;
        bottom: 0;
        font-weight: 400;
    }
}

.login-form {
    width: 70%;
    margin: 0 auto;

    .login-body {

        img {
            width: 178px;
        }

        h2 {
            color: #050814;
            font-weight: 800;
            font-size: 36px;
            margin: 35px 0;
        }

        .login-fields {
            margin-top: 80px;
        }

        .forgot-pass {
            a {
                font-weight: 700;
                color: $primary-color;
                text-decoration: underline;
                font-size: 13px;
            }
        }
    }

    .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
        height: 36px !important;
    }
}

// AUTHENTICATION MODULE ENDS

// SIDEBAR STARTS
.sidebar {
    height: 100vh;

    .sidebar-content {
        width: 260px;
        transition: 0.3s ease;
        background: #fff;
        overflow-x: hidden;
        padding: 0 20px;
        border-right: 2px solid #DBE0E7;

        .nav-collapse-btn {
            cursor: pointer;
            transition: 0.3s ease;

            &.rotate {
                transform: rotate(180deg) !important;
            }
        }

        .logo {
            height: 50px;
        }

        &.close {
            padding: 0 20px;
            width: 90px !important;

            .close-icon {
                display: none !important;
            }

            .nav-item {
                width: 43px;
                // padding: 10px;
            }

            .logo {
                img {
                    width: 43px;
                }
            }

            .navigation {
                margin-top: 60px;

                .nav-item {

                    .expand-section {
                        display: none;
                    }

                    mat-icon {
                        display: none;
                    }

                    svg.full {
                        margin-right: 0;
                    }

                    .menu-icon {
                        width: 24px;
                    }
                }



                .expandable-menu {
                    .expand-section {
                        display: none;
                    }

                    &#team.expand {
                        height: auto !important;
                    }

                    &#order.expand {
                        height: auto !important;
                    }

                    &#settings.expand {
                        height: auto;
                    }
                    &#masterTracking.expand {
                        height: auto;
                    }
                }

            }

            .closed-btn {
                width: 44px;
                text-align: center;
            }
        }

        .logo {

            img {
                width: 150px;
            }

        }

        .navigation {
            svg {
                margin-right: 10px;

                path {
                    stroke: #203E59;
                }
            }

            .nav-item.active .path-color {
                path {
                    fill: #203E59 !important;
                }
            }

            margin-top: 60px;

            .nav-item {
                margin-bottom: 10px;
                border-radius: 10px;
                padding: 10px;
                transition: 0.3s ease;

                img {
                    width: 24px;
                    margin-right: 10px;
                }

                span {
                    font-family: 'Inter', sans-serif !important;
                    color: #203E59;
                    font-weight: 500;
                    font-size: 17px;
                    display: inline-block;
                    transform: translateY(1px);
                }
            }

            .nav-item.mat-mdc-list-item-interactive::before {
                border-radius: 10px !important;
            }

            .nav-item.active {
                background: $primary-color;
            }

            .nav-item.active span {
                color: #fff;

            }

            .nav-item.active svg {
                path {
                    stroke: #fff !important;
                }
            }

            .nav-item.active .path-color {
                path {
                    fill: #fff !important;
                }
            }
        }

    }


    .sidebar-content::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .sidebar-content::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
    }

    .sidebar-content::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 40px;
    }

}

.overlay {
    width: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    height: 100vh;
    display: none;
    transition: 0.3s;

    &.mobile-open {
        display: block;
    }
}

@media(max-width: 992px) {
    .sidebar-content {
        position: fixed;
        z-index: 1000;
        left: -2000px;
        transition: 0.3s;

        &.mobile-open {
            left: 0;
        }
    }
}

// SIDEBAR ENDS

// HEADER STARTS
header {
    transition: 0.3s;

    &.header-hide {
        margin-top: -60px !important;
    }

    h1 {
        color: $primary-color !important;
        font-weight: 500 !important;
        font-size: 30px !important;
        font-family: 'Poppins', sans-serif !important;
    }

    .heading-text {}

    .user {
        position: relative;
        z-index: 2;
        padding: 15px 15px;
        box-shadow: 0px 0px 8px -4px #000;
        border-radius: 10px;
        cursor: pointer;
        background: #fff;
        margin-bottom: 20px;

        img {
            width: 36px;
            margin-right: 10px;
        }

        h2 {
            font-family: 'Inter', sans-serif !important;
            color: #0C2B40;
            font-weight: 500;
            font-size: 15px;
        }

        mat-icon {
            font-size: 20px;
            height: 21px;
            width: 20px;
            margin-left: 2px;
            color: #2E2E2E;
            font-weight: 800;
        }
    }
}

.user-menu {
    width: 180px;
    box-shadow: 0px 2px 7px -5px #000 !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    top: -10px;
    color: #9A9DA2 !important;

    mat-icon {
        font-size: 18px !important;
        height: 18px !important;
        width: 18px !important;
        color: #7f8286 !important;
    }
}

// HEADER ENDS

// LIST STYLE STARTS
.list-card {
    width: 100%;
    padding: 20px;
    // box-shadow: 0px 2px 8px -5px #000;
}

.master-data .list-card {
    padding: 20px 0 !important;
}

.master-data .custom-datatable .table-column {
    min-width: 28%;
}

.master-data .custom-datatable {
    height: calc(100vh - 21rem);
}

.master-data .custom-datatable.product-type .table-column {
    min-width: 48.4%;
}

.master-data .list-card .list-search .list-search-field {
    width: 100% !important;
}

.list-search-field {

    .mdc-notched-outline__leading .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: $secondary-color !important;
    }

    .mat-mdc-paginator .mdc-notched-outline__leading,
    .mat-mdc-paginator .mdc-notched-outline__notch,
    .mat-mdc-paginator .mdc-notched-outline__trailing {
        border-color: rgba(23, 119, 208, 0.5);
    }

    .mat-mdc-paginator .mat-mdc-select-value-text {
        color: $primary-color !important;
        font-weight: 600 !important;
    }

}

// LIST STYLE ENDS


// CUSTOM CARD STYLES STARTS

.custom-card {
    background-color: #fff;
    padding: 10px 0;
    border-radius: 10px;
    min-height: 240px;
}

.custom-tab-navigation {
    font-size: 14px;
    font-weight: 600;
    color: #A7A9AC;
    text-decoration: none;
    padding: 13px 0;
    margin: 0 20px 0 10px;
    font-family: 'Inter', sans-serif !important;
}

// @media (min-width: 1400px){
//     .custom-tab-navigation {
//         font-size: 14px;
//         font-weight: 600;
//         color: #A7A9AC;
//         text-decoration: none;
//         padding: 13px 0;
//         margin: 0 20px 0 10px;
//         font-family: 'Inter', sans-serif !important;
//     }
//   }
  
  @media (max-width: 1400px){
    .custom-tab-navigation {
        font-size: 12px;
        font-weight: 600;
        color: #A7A9AC;
        text-decoration: none;
        padding: 13px 0;
        margin: 0 12px 0 10px;
        font-family: 'Inter', sans-serif !important;
    }
  }

.custom-tab-navigation.active {
    border-bottom: 4px solid #1777D0;
    color: #1777D0;
}

.tab-nav-grey-line {
    border-bottom: 1px solid #D2D5DC;

}

// CUSTOM CARD STYLES ENDS


// CUSTOM TABLE LISTING STARTS
.list-card {
    width: 100%;
    padding: 20px 0 5px !important;
    box-shadow: 0px 2px 8px -5px #000;
    background: #fff;
    border-radius: 8px;

    &.customers {
        padding-top: 0 !important;
    }
}

.list-search {
    padding: 0 20px;

    &.with-filter {
        .list-search-field {
            width: 45% !important;
        }
    }

    .paginator-text {
        display: none !important;
    }

    .mat-mdc-paginator-page-size-label {
        display: none !important;
    }

    .list-search-field {
        width: 15%;
    }

    h3 {
        color: #1777D0;
        font-weight: 600;
        font-size: 16px;
    }

    .add-btn {
        padding: 5px 0px 5px 7px;
        margin-left: 15px;
        border: 2px solid $primary-color;
        border-radius: 8px;
        width: 52px;

        mat-icon {
            font-size: 28px;
            width: 28px;
            height: 25px;
            line-height: 31px;
            color: $primary-color;
        }
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
        height: 39px;
        width: 39px;
    }
}

.list-search-field .mat-mdc-form-field-infix {
    min-height: 40px !important;
}

.list-search-field .mat-mdc-text-field-wrapper {
    height: 40px !important;
}

.list-search-field .mdc-notched-outline__leading,
.list-search-field .mdc-notched-outline__notch,
.list-search-field .mdc-notched-outline__trailing {
    border-color: rgba(23, 119, 208, 0.5);
}

.list-search-field input::placeholder {
    color: #C6C6C6 !important;
    font-weight: 500;
    font-size: 15px !important;
}

.list-search-field mat-icon {
    color: $primary-color;
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.search-page-divider {
    height: 32px;
    width: 1px;
    background-color: #DCDCDC;
}

.mat-mdc-paginator {
    background: transparent !important;
}

.header-toggle-icon {

    // transition: 0.5s;
    &.header-hide {
        transform: rotate(180deg);
    }
}

.activity-btn {
    padding: 6px 18px;
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: transparent !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000 !important;
    border-radius: 15px;
    transition: all .2s;

    mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
    }
}

.activity-btn:hover {
    color: #fff !important;
    transition: all .2s;
    background-color: $secondary-color !important;
    border: 1px solid #fff;

    svg {
        path {
            stroke: #fff !important;
        }
    }
}

.custom-datatable.body-border {
    .table-body-row .table-column {
        // border-bottom: none !important;
    }

    .table-body-row {
        border-bottom: 1px solid #DCDCDC !important;
    }
}

.list-header {
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 10px !important;

    h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: #000;
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    height: 38px !important;
    padding: 9px !important;
}

.master-data {

    .custom-datatable {

        .table-body-row:hover .table-column {
            background: #dfefff !important;
        }

        .table-column.sticky-column {
            background: #fff !important;
        }

        .table-header {
            background: #fff !important;

            .table-column {
                background: #fff !important;
            }
        }

    }

}

.custom-datatable {

    transition: 0.3s;

    &.header-hide {
        height: calc(100vh - 13rem) !important;
    }

    height: calc(100vh - 16rem);
    ;
    overflow: auto;

    .table-header {
        padding: 0;
        position: sticky;
        top: 0;
        z-index: 100;
        border-bottom: 1px solid #DCDCDC;
        background: #ffffff;

        .table-column {
            padding: 10px 0;
            // background: $table-heading-color;
            border-bottom: 1px solid #DCDCDC !important;
            background: #ffffff;
            margin-bottom: -1px;

            img {
                width: 16px;
                height: 17px;
                margin-left: 7px;
                cursor: pointer;
            }
        }

        .table-column.sticky-column {
            // background: $table-heading-color;
        }
    }

    .table-body-row {
        padding: 0;
        border-bottom: 1px solid #DCDCDC;
        // background: #fff;

        &:hover {
            background: #dfefff;

            .table-column {
                background: #dfefff;
            }
        }

        .table-column {
            border-bottom: 1px solid #DCDCDC !important;
            margin-bottom: -1px;

            // background: #fff !important;
            img.person-img {
                width: 24px;
                margin-right: 10px;
                border-radius: 50%;
                height: 24px;
            }
        }

        .table-column.sticky-column {
            // background: #FFF;
            border-bottom: 1px solid #DCDCDC !important;
        }
    }

    .table-column {
        min-width: 16%;
        height: 60px;
        display: flex;
        align-items: center;

        h3 {
            color: #263238;
            font-weight: 400;
            font-size: 14px;
        }

        h4.first-col {
            color: #263238 !important;
        }

        h4 {
            font-weight: 400;
            color: #818287;
            font-size: 13px;
        }

        h4.amount {
            font-weight: 600 !important;
            color: #2E2E2E !important;
        }

        h4.id {
            font-weight: 600 !important;
            text-transform: uppercase !important;
        }

    }

    .table-column.sticky-column {
        position: sticky;
        left: 0px;
        z-index: 2;
        padding-left: 20px !important;
        background: #ffffff;
    }

    .table-column.md {
        min-width: 220px !important;
    }

    .table-column.l {
        min-width: 290px !important;
    }

    .table-column.xl {
        min-width: 320px !important;
    }

    .table-column.xxl {
        min-width: 334px !important;
    }

    .table-column.s {
        min-width: 150px !important;
    }

    .table-column.xs {
        min-width: 120px !important;
    }

    .table-column.xxs {
        min-width: 80px !important;
    }

    .table-column.name-col {}

    iframe {
        width: 100%;
        height: 70%;
        margin-top: 20px;
    }

}

.user-table {
    iframe {
        width: 100%;
        height: 50% !important;
    }

    .table-header-inner {
        background: #fff;
    }

    .sticky-column,
    .table-column {
        background: #fff !important;
    }

    .table-body-row:hover .table-column {
        background: #dfefff !important;
    }
}

.status-text {
    padding: 8px 15px;
    color: #f0f0f0;
    border-radius: 8px;
    font-weight: 600;
    max-width: 111px;
    margin-right: 10px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-text.detail-status {
    max-width: unset !important;
}

.status-text.active {
    background: rgba(0, 199, 152, 0.3) !important;
    color: #00b190 !important;
}

.status-text.inactive {
    background: rgba(249, 92, 92, 0.3) !important;
    color: #ed455d !important;
}

.status-text.trip-complete {
    background: #239DE3 !important;
    color: #fff !important;
}

.status-text.decline {
    background: rgb(246 163 93 / 30%) !important;
    color: #e88a5a !important;
}

.status-text.process {
    background: rgb(23 119 208 / 30%) !important;
    color: #1274ef !important;
}
.status-text.in-transit {
    background: rgb(23 119 208 / 30%) !important;
    color: #1274ef !important;
}
.status-text.on-hold {
    background: rgb(201 201 201 / 30%) !important;
    color: #5d5d5d !important;
}

.status-text.no-status{
    color: #818287 !important;
font-weight: 400   !important;
font-size: 13px;
}

.paginator {
    padding: 0 10px;
    margin-left: 10px;

}

.mdc-list-item--selected .mdc-list-item__primary-text {
    color: #1777D0 !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: #C4E3FF;
}

.table-header::-webkit-scrollbar {
    display: none !important;
    /* Safari and Chrome */
}

.custom-datatable::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-datatable::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.custom-datatable::-webkit-scrollbar-thumb {
    background-color: #7e7e7e;
    border-radius: 40px;
}

@media(min-width:1600px) {
    .custom-datatable .table-column {
        min-width: 18.5% !important;
    }
}

@media(max-width:1400px) {
    .custom-datatable .table-column {
        min-width: 197px !important;
    }
}

// CUSTOM POPUP DESIGN STARTS
.add-popup {
    border-radius: 15px;

    .popup-header {
        width: 100%;
        height: 250px;
        background: #38B6FF;
        padding: 50px 20px 20px;
        text-align: center;

        h2 {
            font-size: 34px;
            color: #fff;
            font-weight: 400;
        }

        img {
            width: 202px;
            margin-top: 50px;
        }
    }

    .close-btn {
        position: absolute;
        top: 5px;
        right: 15px;

        svg {
            width: 35px;
        }
    }

    .popup-body {
        height: 380px;
        width: 100%;
        background: #FFF;
        padding: 110px 80px 40px;
    }
}

.popup-input .mdc-notched-outline__leading,
.popup-input .mdc-notched-outline__notch,
.popup-input .mdc-notched-outline__trailing {
    border-color: #D9D9D9 !important;

}
.error-message{
    .mat-mdc-form-field-subscript-wrapper{
        display: block !important;
    }
    .mat-mdc-form-field-bottom-align::before{
        display: inline;
    }
    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
        padding: 0 0 !important;

    }
}
.input-desc-pipe {
    position: absolute;
    right: 0;
    top: 0px;
    color: #7b7b7b;
    font-size: 15px;
    height: 100%;

    .pipe {
        height: 70%;
        width: 1px;
        background: #b2b2b2;
        margin-right: 10px;
    }
}

.popup-input .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    height: 37px !important;
}

.popup-input textarea.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    height: 60px !important;
}

// TRIP DETAILS STARTS
.trip-empty-placeholder {
    width: 100%;
    height: 200px;
}

.trip-details {

    .location-info {
        padding: 25px 30px !important;

        h3 {
            font-weight: 600;
            color: #000;
            font-size: 16px !important;

            span {
                font-weight: 400 !important;
            }
        }

        h4 {
            color: #2E2E2E;
            font-weight: 400;
            font-size: 15px;
        }

        h5 {
            color: #7C7C7C;
            font-weight: 400;
            font-size: 14px;
        }

        h6 {
            color: $primary-color;
            font-weight: 400;
            font-size: 14px;
            line-height: 12px;
            margin-bottom: 5px !important;
        }

        .payment-status {
            border: 1px solid rgba(23, 119, 208, 0.5) !important;
            width: 110px;
            margin-top: 20px;
            color: #1777D0;
            font-weight: 500;
            font-size: 13px;
            text-align: center;
            padding: 7px 7px;
            border-radius: 5px;
        }

        .destination {

            border-right: 1px solid #D9D9D9;

            .destination-points {

                svg.location-line {
                    margin-left: 6.5px !important;
                }

                .pickup-point {
                    margin-left: 10px;
                    margin-bottom: 14px;
                    border-bottom: 1px solid #D2D5DC;
                    padding-bottom: 11px;
                }

                .drop-off-point {
                    margin-left: 10px;
                }

            }

        }

        .vehicle-details {

            padding-left: 20px;

            .edit-icon {
                height: 28px;
            }

            .vehicle-detail-points {
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 15px;
            }

        }
    }

    .order-enquiry {
        .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .mat-mdc-text-field-wrapper {
            height: 38px !important;
        }

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
            top: 16px;
            font-size: 12px;
        }

        .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
            height: 38px !important;
            font-size: 14px !important;
        }

        .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input::placeholder,
        .mat-mdc-select-placeholder {
            font-size: 12px !important;
        }

        .mat-mdc-icon-button.mat-mdc-button-base {
            width: 38px !important;
            height: 38px !important;
            padding: 2px !important;
        }

        .mat-mdc-form-field-flex,
        .mat-mdc-select-value {
            height: 38px !important;
        }

        .mat-mdc-select-placeholder {
            display: inline-block;
            margin-top: 8px;
        }

        .mat-mdc-select-value-text {
            font-size: 14px;
            margin-top: 7px;
            display: inline-block;
            width: 90%;
        }

        .mat-mdc-text-field-wrapper {
            background: #E8F6FF;
        }

        .select-field .mdc-notched-outline__leading,
        .select-field .mdc-notched-outline__notch,
        .select-field .mdc-notched-outline__trailing {
            border-color: #E8F6FF !important;
        }

        .mat-mdc-text-field-wrapper.mdc-text-field--outlined textarea.mdc-text-field__input {
            padding-top: 7px !important;
            padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
            height: auto !important;
            background: #E8F6FF;
        }

        .text-area .mat-mdc-text-field-wrapper {
            padding: 0 !important;
        }
    }

    .payment-details {

        .pricing {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: #D2D5DC !important;
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
                height: 35px !important;
                color: #000 !important;
            }

            .mdc-text-field--focused:not(.mdc-test-field--disabled) .mdc-floating-label {
                color: #263238 !important;
                font-weight: 500 !important;
            }

            .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
                font-weight: 500;
                color: #263238 !important;
            }

        }

        .payment-table {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: #D2D5DC !important;
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .mat-mdc-text-field-wrapper,
            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
                height: 35px !important;
            }

            .mat-mdc-text-field-wrapper,
            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input::placeholder {
                font-size: 13px !important;
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input::placeholder {
                font-size: 14px;
                color: #A7A9AC;
            }

            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
                top: 14px !important;
                font-size: 13px;
                color: #A7A9AC;
            }

            .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
                top: 29px !important;
            }

            .mat-mdc-form-field-flex {
                height: 35px !important;
            }

            .date-picker {
                svg {
                    width: 16px !important;
                }
            }

            .amount {
                p {
                    position: absolute;
                    color: #A7A9AC;
                    font-size: 16px;
                    font-weight: 700;
                    top: 6px;
                    right: 0;
                }

                .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
                    width: 85% !important;
                }
            }

        }

    }

}

//FILTER
.status-list {
    .mat-mdc-checkbox label {
        font-size: 14px;
        font-weight: 400;
        color: #6B6E7A;
        font-family: "Poppins", sans-serif !important;
    }
}

// DOCUMENT 
.document-popup {
    .popup-header img {
        width: 142px !important;
        margin-top: 10px !important;
    }

    .popup-header {
        height: 180px !important;
    }

    .popup-body {
        padding: 110px 30px 40px !important;
        height: 410px !important;
    }

    .document-card {
        width: 28%;
        height: 170px;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 1px 9px -3px #000;
        border-radius: 5px;
        margin-bottom: 30px;

        .document-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            display: none;
        }

        mat-icon.close {
            top: 0px !important;
            right: 0 !important;
            font-size: 24px !important;
            height: 24px !important;
            width: 24px !important;
            background: #F95C5C !important;
            color: #fff !important;
        }
    }

    .document-card:hover .document-overlay {
        display: flex !important;
    }

    .document-card.add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed #1777D0;
        box-shadow: none;
        cursor: pointer;

        mat-icon {
            font-size: 50px;
            height: 50px;
            width: 50px;
            color: #1777D0;
        }

        h3 {
            font-size: 16px;
            margin-top: 5px;
            font-weight: 600;
            color: #1777d0;
        }

    }

    .document-card.add:hover {
        background: #d3d3d3;
    }
}

//
.star {
    color: rgb(255, 34, 34);
}

.enquiry-details {

    padding: 10px 30px 80px !important;

    h5 {
        color: #7C7C7C;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px !important;
    }

    h4 {
        color: #2E2E2E;
        font-weight: 400;
        font-size: 15px;
    }

    h3 {
        color: #F6A35D;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
        margin-top: 6px;
    }

    .status-text {
        padding: 10px 20px !important;
        display: inline-block !important;
    }

    .enquiry-status {
        border-right: 1px solid #D9D9D9;
    }

    .enquiry-date {
        padding-left: 30px !important;
    }

    .booking-date {
        padding-left: 30px !important;
    }

}

.w-40 {
    width: 40%;
}

.h-100px {
    height: 100px;
}

.payment-details {
    padding: 10px 30px;

    .table-delete {
        font-size: 22px;
        width: 22px;
        height: 22px;
        margin-left: 15px;
        color: #F95C5C;
    }

    h5 {
        color: #1777D0;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px !important;
    }

    h4 {
        color: #000;
        font-size: 13px;
        font-weight: 400;
    }

    h6 {
        color: #2E2E2E;
        font-weight: 400;
        font-size: 14px;

        span {
            color: #1777D0;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .payment-input {
        width: 100px !important;
        margin-right: 15px;
    }

    .payment-input.discount {
        width: 73px !important;
    }

    .amount-box {
        margin-right: 30px;
    }

    .gst-box {
        margin-left: 10px;
    }

    .payment-summary {
        background: #C3E5F4;
        padding: 40px 55px 30px;
        border-radius: 8px;
        margin-top: 5px;

        h3 {
            color: #263238;
            font-weight: 500;
            font-size: 13px;
            line-height: 10px;
        }

        h2 {
            color: #1777D0;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .payment-table {
        margin-top: 5px;

        .custom-datatable {
            height: calc(100vh - 27rem) !important;

            .table-header {

                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                padding: 0 !important;

                .table-column:first-child {
                    padding-left: 20px !important;
                    border-top-left-radius: 8px;
                }

                .table-column:last-child {
                    border-top-right-radius: 8px;
                }

            }

            .table-body-row:nth-child(even) {
                .table-column {
                    background: #FDFAFA !important;
                }
            }

            .table-body-row {
                padding: 0 !important;

                .table-column:first-child {
                    padding-left: 20px !important;
                }

                .table-column {
                    padding-bottom: 0 !important;
                }
            }

            .table-column {
                width: 25% !important;
                border: none !important;

                h4 {
                    color: #A7A9AC !important;
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    width: 90% !important;
                }
            }
        }

        .primary-btn {
            font-size: 14px !important;
            color: #F0F0F0 !important;
            width: 200px !important;
            height: 40px !important;
            font-weight: 500 !important;

        }
    }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: #005691 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #005691;
}

.payment-details .custom-datatable iframe {
    width: 100%;
    height: 50%;
    margin-top: 20px;
}

.edit-icon {
    color: #5fb6dd;
}

.trip-details .payment-details .payment-table .payment-status .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 5px !important;
}

.payment-details .mat-mdc-select-value-text {
    font-size: 14px !important;
}

.trip-details .payment-details .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px !important;
}

.trip-details .payment-details .payment-table .payment-status .mat-mdc-select-placeholder {
    color: #A7A9AC !important;
}

.trip-details .payment-details .pricing .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input::placeholder {
    font-size: 13px !important;
}

.trip-details .payment-details .payment-table .payment-status .mat-mdc-select-arrow-wrapper svg path {
    stroke: #A7A9AC !important;
}

.trip-details .payment-details .pricing .payment-input .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    font-weight: 400 !important;
    color: #818181 !important;
    font-size: 13px !important;
}

@media(max-width:1450px) {
    .payment-details h6 {
        font-size: 12px !important;
    }

    .payment-details .amount-box {
        margin-right: 10px !important;
    }

    .payment-details .payment-input {
        margin-right: 10px !important;
    }
}

.order-details {
    padding: 0 30px 50px !important;

    h5 {
        color: #7C7C7C;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px !important;
    }

    h4 {
        color: #2E2E2E;
        font-weight: 400;
        font-size: 15px;
    }

    .status-text {
        padding: 10px 20px !important;
        display: inline-block;
    }

    .order-info {
        border-right: 1px solid #D9D9D9;
        padding-right: 40px;
    }

    .driver-info {
        padding-left: 20px;
    }
}

.wrap-text {
    overflow: hidden;
    /* Hides the overflow content */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    text-overflow: ellipsis;
    /* Displays '...' at the end if the text overflows */
}

.drp-loc {
    .mat-mdc-form-field-infix {
        min-height: 39px !important;
    }
}


@media (max-width: 992px) {

    header h1,
    header .h1 {
        font-size: 20px !important;
    }

    .enquiry-area {
        padding: 10px !important;
    }

    .mdc-dialog .mdc-dialog__surface {
        max-width: 450px !important;
        min-width: 450px !important;
    }

    .list-header h1 {
        font-size: 20px;
    }
}

@media (max-width: 420px) {
    .mdc-dialog .mdc-dialog__surface {
        max-width: 350px !important;
        min-width: 350px !important;
    }
}


.supplier-list {

    .mdc-checkbox__background {
        width: 16px !important;
        height: 16px !important;
        border-radius: 3px !important;
    }

    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: rgba(0, 0, 0, 0.5) !important;
        border-width: 1px;
    }
}

.sup-vehicle{
    .upper-pagination{
        .paginator-text, .mat-mdc-paginator-page-size-label{
            display: none;
        }
    }
}

.menu-dropdown {
    background-color: #ffff;
    border-radius: 12px;

    mat-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
    }

    .list-down {
        transition: all .2s;
        padding: 9px;
        background-color: #fff;

        p {
            font-size: 13px;
            font-weight: 400;
            color: #000000;

        }

        &:hover {
            transition: all .2s;
            background-color: #EBF2F8;
            border-radius: 6px;
            transition: all .2s;

            p {
                color: #1777D0;
            }
        }
    }

    .list-down.active {
        background-color: #EBF2F8;
        border-radius: 6px;
        transition: all .2s;

        p {
            color: #1777D0;
        }
    }

    .list-down svg path {
        stroke: #000000;
    }

    .list-down:hover svg path {
        transition: all .2s;
        stroke: #EBF2F8;
    }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    min-width: 148px !important;
    max-width: 280px;
    border-radius: 12px;
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0px 4px 10px 0px rgba(66, 93, 97, 0.15);
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

.new-input-field {
    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #000;

    }

    input::placeholder {
        color: #A7A9AC;
        font-size: 14px;
    }

    .defination {
        font-size: 12px;
        font-weight: 400;
        color: #A7A9AC;
    }
}

.profile-img-form{
    p{
    font-size: 14px;
    font-weight: 400;
    color: #A7A9AC;
    }
    h6{
        font-size: 14px;
        font-weight: 600;
        color: #1777D0;

    }
    .border-img{
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        height: 100px;
        width: 200px;
        background-color: #E2F1FF;
        text-align: center;
            line-height: 97px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            // object-fit: none;
            
        }
        .upload{
            width: 40px;
            height: 40px;
        }
    }
}

.add-supplier-page{
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 4px !important;
    }
    .mat-mdc-form-field-flex{
        height: 44px;
    }
    .mat-mdc-text-field-wrapper{
        height: 44px;
    }
}

.ngx-foreground-spinner, .ngx-progress-bar{
    color: #1777d0 !important;
}

.back-main-page {

    .back-main-arrow {
        width: 40px;
        height: 40px;
        border: 1px solid #0000001A;
        border-radius: 6px;
        text-align: center;
        line-height: 38px;
    }

    .back-para {
        font-size: 12px;
        font-weight: 400;
        color: #A7A9AC;
    }

    .main-page {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 24px;
    }
}
// PROFILE PAGE
.profile-page {
    
    .profile-img {
        width: 70px;
        height: 70px;
        border-radius: 50px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50px;
        }
    }

    .profile-name {
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
    }

    .profile-email {
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
    }
}

// TABS
.tabs-container {
    //margin-top: 30px;
    position: sticky;
    top: 0.5px;
    background: #fff;
    z-index: 9;
    border-bottom: 0.5px solid #d0d0d0;
    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.5);
        padding: 10px 0;
        font-size: 16px;
        font-weight: 400;

        // svg path {
        //     stroke: rgba(0, 0, 0, 0.5);
        // }
        svg path,
        svg circle,
        svg rect {
            stroke: rgba(0, 0, 0, 0.5);
        }

        .location-range {
            svg path {
                fill: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .location-range {
        svg path {
            fill: rgba(0, 0, 0, 0.5);
        }

    }

    a.active {

        svg path,
        svg circle,
        svg rect {
            stroke: #1777D0;
        }

        color: #1777D0;
        padding: 10px 0;
        border-radius: 5px 5px 0 0;
        // border-bottom: 1px solid #d0d0d0;
        font-size: 16px;
        font-weight: 500;
    }

}

.vehicle-caps{

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
        padding-top: 16px !important;
    }
    .mat-mdc-select-placeholder{
        font-size: 14px;
    }
}

.payment-column {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 700 !important;
}

.pagination-design{
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 3px !important;
    }
    .mat-mdc-text-field-wrapper{
        height: 32px;
    }
}

.cookie-desc{
    .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex{
        height: auto;
        min-height: 44px;
        max-height: 500px;
    }
}
